import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Lumia Gestures",
  "date": "2014-12-21T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://blogs.msdn.com/b/lumia_beta_apps/archive/2014/12/15/manage-calls-more-naturally-with-gestures-beta-try-it-out-and-give-us-feedback.aspx"
      }}>{`Microsoft recently announced Gestures for Lumia phones`}</a>{`, which are basically shortcut gestures for things you commonly do when talking on the phone. I like how each of these gestures simply build upon things you’d already do. Very clever. I would love to see these come to iOS.`}</p>
    <p><a parentName="p" {...{
        "href": "http://blogs.msdn.com/b/lumia_beta_apps/archive/2014/12/15/manage-calls-more-naturally-with-gestures-beta-try-it-out-and-give-us-feedback.aspx"
      }}>{`You can watch the preview video on Microsoft’s blog.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      